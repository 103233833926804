

function stretch() {
	"use strict";
	
	var leftArrow = document.getElementById('left-arrow');
	leftArrow.style.color = 'rgb(150,150,150)';
	
	var rightArrow = document.getElementById('right-arrow');
	rightArrow.style.color = 'rgb(150,150,150)';
	
    var h = document.getElementById('stretc-h');
	h.style.letterSpacing = '0.36em';
	
}
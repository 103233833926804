/**
 * @license (с) 2015—2022 Leto!
 * @preserve www.dance-leto.ru
 */

/**
 * Time-table js
 * Version: see njk-data.json > jsVersion
 *
 * 1.25 Added switch Parnas Zarechnaya
 * 1.24 Removed Pargolovo loc
 * 1.23 Added switch Parnas NR5
 * 1.22 Added Kuzmolovsky
 * 1.21 Rent fix (loc 0 broke the rent time-table)
 * 1.20 Pargolovo: Tihookeanskaya address update
 * 1.19 Separate rooms switch (All > Choose...)
 * 1.18 Pargolovo switch
 * 1.17 Pargolovo time
 * 1.16 Baltyiskaya removed
 * 1.15 Vartemyagi switch
 * 1.14 Baltyiskaya switch
 * 1.12 Baltyiskaya time
 * 1.11 Vladimirskaya time
 * 1.10 Vladimirskaya rent time
 * 1.9 Two rooms in Ozerki (big & small)
 * 1.8 Four rooms in Ozerki (1-4)
 * 1.7 Clean online with all locations
 * 1.4.1 Upgraded to Bootstrap 4.4.1
 * 1.3.1 Removing debug logging
 * 1.3 Adding age switch
 * 2.9 Removing Vyborgskoye loc
 * Reformatted
 * Now with better custom 'No classes' element
 * Shows 2 days in time-table list (merge from time-2.8-2d.js)
 */


// All the real location IDs (except 0)
var locIds = [2, 3, 7, 6, 9, 11, 14, 15];

// global location, defaults to all
var loc = loadLoc();
var age = loadAgeJs(); // 0 = all; 1 = adults; 2 = kids
var locsw = false;

var BASE_URL = "https://42-dot-leto-timetable.appspot.com/leto_timetable_gae?s=";

var DAYS_MAX_ATTR = "data-days";
var AGE_FILTER_ATTR = "data-age";




function saveLoc() {
    "use strict";

    var d = new Date();
    d.setTime(d.getTime() + (365 * 3 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "loc=" + loc + "; " + expires + "; path=/";
}

function getCookie(cname) {
    "use strict";

    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "0";
}

function loadLoc() {
    "use strict";
    var locStr = getCookie('loc');
    var locNum = parseInt(locStr, 10);

    if (!locIds.includes(locNum)) {
        return "0";
    }
    return locStr;
}

function saveAgeJs(a) {
    age = a;
    var d = new Date();
    d.setTime(d.getTime() + 94608000000);
    document.cookie = "age=" + a + "; expires=" + d.toUTCString() + "; path=/";
}

function loadAgeJs() {
    var i = document.cookie.indexOf('age=');
    var localAge;
    if (i === -1) {
        localAge = '0';
    } else {
        localAge = document.cookie.charAt(i + 4);
    }
    $("#option" + localAge).prop("checked", true);
    $("#ageOption" + localAge).addClass("active");

    return localAge;
}

function onAgeSwitch(a) {
    "use strict";
    saveAgeJs(a);
    mainTimetable();
}

function onSwitch() {
    "use strict";
    loc = document.getElementById("locswitch").value;
    updateLegend();
    mainTimetable();
    saveLoc();
}

function updateLegend() {
    "use strict";


    if (loc === "0") {
        // hide all the locations
        for (var i = 0; i < locIds.length; i++) {
            var locEl = document.getElementById("loc" + locIds[i]);
            hideLoc(locEl);
        }
    } else {
        showLoc(document.getElementById("loc" + loc));
        for (var i = 0; i < locIds.length; i++) {
            if (locIds[i] == loc) {
                continue;
            }
            var locEl = document.getElementById("loc" + locIds[i]);
            hideLoc(locEl);
        }
    }
}

function updateOpenLegend() {
    "use strict";

    var oloc1 = document.getElementById("oloc1");
    var oloc2 = document.getElementById("oloc2");
    var oloc3 = document.getElementById("oloc3");


    var oloc2oz = document.getElementById("oloc2oz");
    var oloc1oz = document.getElementById("oloc1oz");
    var oloc3m = document.getElementById("oloc3m");


    var l1 = $('#openholder .rbi').length + $('#openholder .rsm').length;

    if (l1 > 0) { // loc1
        showLoc(oloc1);
        if (getSize() !== 'S') {
            hideLoc(oloc1oz);
        }
    }

    var l2 = $('#openholder .roz').length;

    if (l2 > 0) { // loc2
        showLoc(oloc2);
        if (getSize() !== 'S') {
            hideLoc(oloc2oz);
        }
    }

    var l3 = $('#openholder .rgs').length + $('#openholder .rgm').length;

    if (l3 > 0) { // loc3
        showLoc(oloc3);
        if (getSize() !== 'S') {
            hideLoc(oloc3m);
        }
    }


}


function showLoc(el) {
    "use strict";

    el.style.display = "inline-block";
}

function hideLoc(el) {
    "use strict";

    el.style.display = "none";
}

var locswitchCode = '{{ locSwitchCode | safe }}';

var openLegendCode = '<div id="olegend" class="arooms"><div id="oloc1" class="aroom"><div class="rtech"></div>Клинский, 25 <span id="oloc1oz">(Технологический)</span></div><div id="oloc2" class="aroom"><div class="rozerki"></div>Есенина, 1 <span id="oloc2oz">(Озерки)</span></div><div id="oloc3" class="aroom"><div class="rgraz"></div>Руставели, 60 <span id="oloc3m">(Гражданский)</span></div></div>';

function createLocswitch() {
    "use strict";

    if (!!!isLocswitchRequired()) {
        return;
    }

    if (locsw) {
        return; // create only once!
    }

    var holder = $('.days');
    //console.info(holder);
    //document.getElementById("navholder");
    if (holder.length === 0) {
        holder = document.getElementById("timeholder");
    }

    $(holder).before(locswitchCode);
    var locswEl = document.getElementById("locswitch");
    debug("loc=" + loc);
    locswEl.value = loc;

    updateLegend();

    locsw = true;
}

function createOpenLegend() {
    "use strict";


    var holder = document.getElementById("openholder");

    $(holder).after(openLegendCode);

}

function createCORSRequest(method, url) {
    "use strict";
    var xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {

        // Check if the XMLHttpRequest object has a "withCredentials" property.
        // "withCredentials" only exists on XMLHTTPRequest2 objects.
        xhr.open(method, url, true);

    } else if (typeof XDomainRequest !== "undefined") {

        // Otherwise, check if XDomainRequest.
        // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
        xhr = new XDomainRequest();
        xhr.open(method, url);

    } else {

        // Otherwise, CORS is not supported by the browser.
        xhr = null;

    }
    return xhr;
}

var ttmain; // Nav block singleton

function getCalendarData(ttid) {
    "use strict";

    if (loc === "0" && isLocswitchRequired()) {
        createLocswitch();
        hideNav();
        hideTimetable();
        return;
    }



    var url = BASE_URL +
        getSize() + getRequest(ttid);

    debug(url);

    var xhr = createCORSRequest('GET', url);
    if (!xhr) {
        //throw new Error('CORS not supported');
        showFallback();
        info("—&nbsp;Попробуйте обновить браузер (это полезно :-)");
    }


    debug("Connecting to GAE...");

    xhr.onreadystatechange = function () {
        debug("onreadystatechange(" + ttid);
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                //debug(xhr.responseText);
                var tt = JSON.parse(xhr.responseText);
                if (ttid === "timeholder") {
                    ttmain = tt;
                }
                //debug("tt.nav: " + tt.nav);

                //debug(tt.feed[0].cont);

                debug("pre showNav");

                if (ttid === "timeholder") {
                    createLocswitch();
                    showNav();
                    navigate(0);
                    showFooter();
                } else {
                    navigate(0, tt, ttid);
                    if (ttid === "openholder" && tt.feed.length > 0) {
                        createOpenLegend();
                        updateOpenLegend();
                    }
                }


            } else {
                if (ttid === "timeholder") {
                    showFallback();
                    info("—&nbsp;Попробуйте обновить страницу (возможно, все исправится).<br />—&nbsp;Звоните в студию и жалуйтесь! +7&nbsp;812&nbsp;565-45-32.");
                }
            }
        }
    };

    xhr.send();
}

var footer = false;

function showFooter() {
    "use strict";

    return; // don't show the footer for the sake of simplicity

    if (loc === "0") {
        return;
    }

    if (footer) {
        return;
    }
    info("Студия живет, проводит занятия и публикует расписание по санкт-петербургскому времени.");
    footer = true;
}


function getRequest(ttid) {
    "use strict";
    // '&' + "u=" + "salsa"
    debug("ttid=" + ttid);
    var holder = document.getElementById(ttid);
    var req = holder.getAttributeNode('treq');
    var ageFilter = holder.getAttributeNode(AGE_FILTER_ATTR);
    var reqAdditional = '&l=' + loc;
    if (ageFilter) {
        reqAdditional += '&age=' + age;
    }
    if (!req) {
        return reqAdditional;
    }
    return '&' + req.value + reqAdditional;
}

function isLocswitchRequired() {
    "use strict";

    var holder = document.getElementById("timeholder");

    if (!!!holder) {
        return false;
    }

    var sw = holder.getAttributeNode('tlocsw');

    if (!!!sw) {
        return true;
    }
    if (sw.value === "0") {
        return false;
    }
    return true;
}

function info(msg) {
    "use strict";

    var dholder = document.getElementById("log");
    if (dholder) {
        var p = document.createElement("p");
        p.innerHTML = msg;

        dholder.appendChild(p);
    }
}

function debug(msg) {
    "use strict";
    // console.log(msg); // uncomment this for debugging
}

function showFallback() {
    "use strict";

    var fholder = document.getElementById("fallback_msg");
    fholder.style.display = "block";
}


// start index
//var index = 0;

function showNav() {
    "use strict";
    debug("showNav");

    var nholder = document.getElementById("navholder");
    if (nholder) {
        nholder.innerHTML = ttmain.nav;
    }
}

function hideNav() {
    "use strict";

    var nholder = document.getElementById("navholder");
    if (nholder) {
        nholder.innerHTML = "";
    }
}


function mainTimetable(ttid) {
    "use strict";
    propagateUTM1();

    var wttid = "timeholder";
    if (typeof ttid !== "undefined") {
        wttid = ttid;
    }

    var holder = document.getElementById(wttid);
    if (!holder) {
        return;
    }
    getCalendarData(wttid);
}

function hideTimetable() {
    var holder = document.getElementById("timeholder");
    holder.innerHTML = "";
}

function navigate(navIndex, tt, ttid) {
    "use strict";

    debug("navigate(" + navIndex);

    if (typeof tt === "undefined") {
        tt = ttmain;
    }

    if (typeof ttid === "undefined") {
        ttid = "timeholder";
    }

    $('#CNC-remove').remove(); // remove 'no classes' custom element

    var holder = document.getElementById(ttid);

    var maxDays = holder.getAttribute(DAYS_MAX_ATTR);
    if (!maxDays) {
        maxDays = 2;
    }
    maxDays--;

    holder.innerHTML = "";


    for (var i = navIndex; i < tt.feed.length; i++) {
        if (i > (navIndex + maxDays)) {
            break;
        }

        var tb = document.createElement("tbody");
        tb.innerHTML = tt.feed[i].cont;
        holder.appendChild(tb);

    }

    if (tt.feed.length === 0 && ttid === "timeholder") {
        var req = holder.getAttributeNode('treq');

        var tbe = document.createElement("tbody");

        tbe.innerHTML = "<tr><td id='CNCPH'></td></tr>";

        var noneText = "нет занятий";

        var customNoClassesEl = $('.customNoClassesEl');
        if (customNoClassesEl.length === 1) {
            customNoClassesEl.clone().attr('id', 'CNC-remove').insertAfter('.days');
        } else {
            holder.appendChild(tbe);
            $('#CNCPH').html(noneText);
        }

    }

    propagateUTM();
    return false;
}

function propagateUTM() {
    if (utmString === '') {
        return; // nothing to propagate
    }
    $(".signup > a").each(function () {
        var url = $(this).attr("href"); // Get current url
        url += '&' + utmString;
        $(this).attr("href", url); // Set href value
    });


}

var utmString = "";
var propDone = false;

// for book button
function propagateUTM1() {
    if (propDone) {
        return; // fire only once
    }

    // Init UTM String
    var s = window.location.search;
    if (s.charAt(0) !== '?') {
        propDone = true;
        return; // no UTMs, exiting
    }
    utmString = s.substring(1);

    $(".utm-prop").each(function () {
        var url = $(this).attr("href"); // Get current url
        if (url.indexOf('?') == -1) {
            url += '?';
        } else {
            url += '&';
        }
        url += utmString;
        $(this).attr("href", url); // Set href value
    });

    propDone = true;
}


function getSize() {
    "use strict";

    if ($('#L').is(':visible')) {
        return 'L';
    }
    if ($('#M').is(':visible')) {
        return 'M';
    }
    return 'S';
}


